<template>
    <div>
        <div id="div">
            <div class="centerTop">
           <div id="show"><h1>服务支持</h1></div>
            <div class="box">
                <ul>
                    <li>
                        <div class="text">
                            <h1>热线服务</h1>
                            <div class="content">
                                <p>专业客服：有经验的销售人员为您服务</p>
                                <p>统一热线：13134285008</p>
                                <p>答疑解惑：有专业的技术人员为您服务</p>
                            </div>
                        </div>
                        <div class="img">
                            <img src="./images/server1.png">
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <h1>上门服务</h1>
                            <div class="content">
                                <p>上门安装：提供上门安装产品服务</p>
                                <p>上门升级：提供技术人员为您升级产品</p>
                                <p>上门维修：提供上门诊断及排除故障</p>
                            </div>
                        </div> 
                        <div class="img2 img">
                            <img src="./images/server2.png">
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <h1>实时服务</h1>
                            <div class="content">
                                <p>价格咨询：提供在线产品价格</p>
                                <p>配置咨询：提供产品安全配置说明</p>
                                <p>产品答疑：提供产品使用答疑</p>
                            </div>
                        </div>
                        <div class="img">
                            <img src="./images/server3.png">
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <h1>产品定制与二次开发</h1>
                            <div class="content">
                                <p>产品定制：提供产品定制方案</p>
                                <p>二次开发：给予定制产品功能二次开发</p>
                                <p>服务团队：提供标准项目开发流程</p>
                            </div>
                        </div>
                        <div class="img2 img">
                            <img src="./images/server4.png">
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
#div{
    width: 100%;
    height: 672px;
    margin-top: 20px;
    margin-bottom:20px ;
    position: relative;
    background: #f8f9fb;
}
.centerTop{
  margin: 0 auto;
   width: 1100px;
    height: 672px;
    position: relative;
}
#show{
    width: 100%;
    height: 100px;
    margin-top:10px;
}
h1{
    text-align: center;
    /* color: #0862ae; */
		color: #795DA3;
    padding-top: 20px;
    font-size: 56px;
}
ul{
	list-style: none;
    width: 1100px;
    height: 572px;
}
ul li{
	float: left;
	width: 400px;
	height: 250px;
    margin-left:100px;
    margin-top: 24px;
    background: white;
    -moz-box-shadow: 2px 2px 10px #909090;
   -webkit-box-shadow: 2px 2px 10px #909090;
   box-shadow:2px 2px 10px #909090;
}
.img img{
    width: 400px;
    height: 250px;
}
.img{
    width: 400px;
    height: 250px;
    margin-top: -250px;
	transform: translateX(0px);
	transition: transform 0.5s ease-out;
}
li:hover .img{
	transform: translateX(-400px);
}
li:hover .img2{
	transform: translateX(400px);
}
.text{
    height: 250px;
    width: 400px;
}
.text h1{
	font-size: 24px;
	margin: 10px 0;
}
.content{
    width: 274px;
    height: 150px;
    margin: 0 auto;
    /* line-height: 30px; */
    
}
.text .content p{
	font-size: 16px;
    padding-top: 15px;
}
.text,.img{
	float: left;
}

.box {
	width: 100%;
	height: 572px;
	position: absolute;
	left: 0;
	top: 100px;
}




</style>