<template>
    <div id="div">
			<div  id="show"><h1>服务项目</h1></div>
        <div id="one">
            <div id="left">
                <dl class="dl">
									<div>
                    <dt class="dt">
                        <div class="iconbg">
														<i class="iconfont icon-zixun" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">项目开发服务</div>
                        <div class="div2">项目策划与公关服务；社会稳定风险评估，软件开发，软件销售，技术开发；技术服务；技术转让，技术推广</div>
                    </dd>
										</div>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-cplus" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">技术服务</div>
                        <div class="div2">提供关于软硬件技术的服务，包括开发服务和售后服务。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
														<i class="iconfont icon-jixieshebei" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">技术转让及销售</div>
                        <div class="div2">本公司产品可进行技术转让及销售。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-fuwu" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">贸易代理</div>
                        <div class="div2">其他:国内一般贸易 采购代理。</div>
                    </dd>
                </dl>
								<dl>
									<dt class="dt">
										<div class="iconbg">
											<i class="iconfont icon-lianxiren"></i>
										</div>
									</dt>
									<dd class="dd">
										<div class="div1">企业征信业务</div>
										<div class="div2">除依法须经批准的项目外 凭营业执照依法自主开展经营活动。</div>
									</dd>
								</dl>
            </div>
        </div>
        <div id="two">
            <div id="right">
                <dl class="dl1">
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-wujingongju" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        
                        <div class="div1">咨询服务</div>
						<div class="div2">咨询策划服务，认证咨询；社会经济咨询服务；安全咨询服务；信息技术咨询服务；环保咨询服务；</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-zhuanrangzhuangtai" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        
                        <div class="div1">销售类服务</div>
                        <div class="div2">计算机硬件及辅助；提供关于绩效管理相关内容的咨询。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-cplus" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">科技中介服务</div>
                        <div class="div2">本公司提供优质的科技中介服务。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-maoyijinchukoulingshoupifa" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">五金产品及电子产品批发零售</div>
                        <div class="div2">五金:建材五金、工具五金、家居五金；电子产品：户外大屏，室内广告屏等。</div>
                        
                    </dd>
                </dl>
								<dl>
									<dt class="dt">
											<div class="iconbg">
													<i class="iconfont iconfont icon-fuwu1" ></i>
											</div>
									</dt>
									<dd class="dd">
											<div class="div1">环境保护监测服务</div>
											<div class="div2">环境保护监测服务，环境应急治理服务</div>
									</dd>
								</dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
	
*{
  margin: 0;
  padding: 0;
}
.dl{
    margin-top: -85px;
}
.dl1{
    margin-top: 40px;
}
#div{
    width: 1100px;
    height: 1344px;
    margin-top: 20px;
}
#one{
    width: 1300px;
    height: 672px;
}
#two{
    width: 1100px;
    height: 120px;
}
#left{
    width:640px;
    height: 672px;
    float: right;
}

#center{
    width: 512px;
    height: 672px;
    float: right;
}
#center img{
    width: 512px;
    height: 327px;
    margin-top: 186px;
}
#center2{
    width: 512px;
    height: 672px;
    float: left;
}
#center2 img{
    width: 512px;
    height: 327px;
    margin-top: 186px;
}
#right{
    width: 440px;
    height: 672px;
    float: left;
		margin-left: -90px;
		margin-top: -800px;
		
}
dl:hover{
    transform:  translate(0,-15px);
}
dl{
	transition: 500ms;
		-moz-box-shadow: 0px 2px 9px 0px rgba(175,175,175,0.5);
		-webkit-box-shadow: 0px 2px 9px 0px rgba(175,175,175,0.5);
		border-radius: 18px;
		border: 1px solid #AFAFAF 50%;
    width: 540px;
    height: 160px;
		margin-top: 39px;
}
.dt{
    width: 100px;
    height: 150px;
    float: left;
}
.dt .iconbg{
    width: 90px;
    height: 90px;
    background-image: linear-gradient(to bottom right, #7aefd5,#52afec, #2835ba);
    border-radius: 10%;
    text-align: center;
    margin-top: 39px;
		margin-left: 39px;
}
.dt .iconbg i{
    display: block;
    font-size: 60px;
    padding-top: 13px;
    color: white;
}
.dd{
    width: 440px;
    height: 150px;
    float: left;
}
.dd .div1{
    width: 340px;
    height: 38px;
    padding-top: 50px;
    font-size: 26px;
    font-weight: bold;
    padding-left: 45px;
}
.dd .div2{
    width: 340px;
    height: 84px;
    font-size: 14px;
    color: #a19a94;
		padding-left: 45px;
}
#show{
    width: 100%;
    height: 218px;
    margin-top:200px;
}
h1{
    text-align: center;
		color: #795DA3;
    padding-top: 20px;
    font-size: 56px;
}
</style>
