<template>
    <div>
				<img src="./images/backgroundImage.png">
    </div>
</template>

<script>
export default {
    data(){
        return{
        }
    }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
div{
    width: 100%;
    height: 854px;
}
img{
    width: 100%;
    height:100%;
}
</style>