<template>
    <div id="div">
        <div id="contact">
            <img class="companyImg"  src="./images/公司标识.png">
            <p>公司地址：辽宁省大连高新技术产业园区大连设计城907室</p>
            <p>Copyright&copy;以恒咨询（大连）有限责任公司</p>
            <p>备案/许可证号：<a style="color:white;" href="http://beian.miit.gov.cn" target="blank">辽ICP备2021007574号-1</a></p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
        }
    }
}
</script>

<style scoped>
img{
		width: 100px;
		/* height: 80px; */
}
*{
		margin: 0;
		padding: 0;
}
#div{
    width: 100%;
    height: 220px;
    background: #05355c;
}
#contact{
    margin: 0 auto;
    width: 1100px;
    height: 150px;
    text-align: center;
    color: white;
    font-size: 18px;
    padding-top: 34px;
}
#contact p{
    margin-top: 8px;
}
.companyImg{
		width: 120px;
		/* height: 40px; */
		margin-bottom: 35px;
		margin-top: 15px;
}
</style>