<template>
	<div class=""><h1>产品示意</h1>
<div class="slide" v-on:mouseover="stop()" v-on:mouseout="move()"> 
  <div class="slideshow"> 
   <transition-group tag="ul" name="image"> 
    <li v-for="(img, index) in imgArray" v-show="index===mark" :key="index"> 
     <a href="#"> 
      <img :src="img">
     </a> 
    </li> 
   </transition-group> 
  </div> 
  <div class="bullet"> 
   <span v-for="(item, index) in imgArray" :class="{ 'active':index===mark }"
   @click="change(index)" :key="index"></span> 
  </div> 
 </div>
 </div>
</template> 

<script> 
export default { 
 data () { 
  return { 
   timer: null, //定时器 
   mark: 0, //比对图片索引的变量 
   imgArray: [ 
		require('./images/product1.png'), 
    require('./images/product2.png'),
		require('./images/product3.png'),
		// require('./images/52.jpg'),
		// require('./images/daping.jpg'),
		// require('./images/13.jpg'),
		// require('./images/16.jpg')
   ] 
  } 
 }, 
 methods: { 
  autoPlay () { 
   this.mark++; 
   if (this.mark === 3) { 
    this.mark = 0 
   } 
  }, 
  play () { 
   this.timer = setInterval(this.autoPlay, 2500) 
  }, 
  change (i) { 
   this.mark = i 
  }, 
  stop () { 
   clearInterval(this.timer) 
  }, 
  move () { 
   this.timer = setInterval(this.autoPlay, 2500) 
  } 
 }, 
 created () { 
  this.play() 
 } 
} 
</script> 

<style scoped>
* { 
  margin: 0; 
  padding: 0; 
  list-style: none; 
 } 
.slide { 
   width: 1024px; 
   height: 800px; 
   margin: 0 auto; 
   margin-top: 50px; 
   overflow: hidden; 
   position: relative; 
	margin-top: 50px;
  } 
.slideshow { 
   width: 1024px; 
   height: 320px; 
  } 
li { 
   position: absolute; 	 
  } 
img { 
	width: 1024px; 
	height:800px; 
	margin-top: -100px;
  }
.bar { 
	position: absolute;
	width: 100%; 
	bottom: 10px; 
	margin: 0 auto; 
	z-index: 10; 
	text-align: center; 
 } 
.bar span { 
   width: 20px; 
   height: 5px; 
   border: 1px solid; 
   background: white; 
   display: inline-block; 
   margin-right: 10px; 
  }
.active { 
	background: red !important; 
 } 
.image-enter-active { 
 transform: translateX(0); 
 transition: all 1.5s ease; 
	} 
.image-leave-active { 
 transform: translateX(-100%); 
 transition: all 1.5s ease; 
	} 
.image-enter { 
 transform: translateX(100%); 
	} 
.image-leave { 
 transform: translateX(0); 
	}
h1{
	text-align: center;
	background-color: #FFFFFF;
	color: #795DA3;
	padding-top: 130px;
	font-size: 56px;
	}
.show{
	width: 100%;
	height:128px;
	margin-top:50px;
	background-color: #FFFFFF;
	}
</style>